import { Link } from 'gatsby';
import React from 'react';
import LegalLayout from '../components/layout/LegalLayout';
import Text from '../components/ui/Text';

const name = 'Slab, Inc.';
const updatedAt = 'Oct 12, 2023';
const website = <Link to="/">https://www.slab.com</Link>;
const privacy = <Link to="/privacy/">Privacy Policy</Link>;
const pricing = <Link to="/pricing/">Pricing Terms</Link>;
const support = <a href="mailto:support@slab.com">support@slab.com</a>;
const mail = (
  <>
    440 North Barranca Ave #8017
    <br />
    Covina, CA 91723
  </>
);

const Terms = () => (
  <LegalLayout title="Terms of Use">
    <section>
      <h1>Terms of Use</h1>
      <p>Last updated: {updatedAt}</p>
    </section>
    <section>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Slab! Your use of Slab&rsquo;s services, including the
        services Slab makes available through this website and any other
        software or services offered by Slab in connection therewith (the
        &ldquo;Services&rdquo;) is governed by these terms of use (the
        &ldquo;Terms&rdquo;), so please carefully read them before using the
        Services. For the purposes of these Terms, &ldquo;we,&rdquo;
        &ldquo;our,&rdquo; &ldquo;us,&rdquo; and &ldquo;Slab&rdquo; refer to
        Fallacy Labs, Inc. (doing business as &ldquo;Slab&rdquo;), the providers
        and operators of the Services.
      </p>
      <p>
        These terms of use are entered into by and between you and Slab. The
        following terms and conditions constitute a legal and binding agreement
        between you and Slab governing your access to and use of {website} (the
        &ldquo;Website&rdquo;), and any other software or Services we offer. If
        you are registering for or using the Services on behalf of an
        organization, you are agreeing to these Terms for that organization and
        promising that you have the authority to bind that organization to these
        Terms. In that case, &ldquo;you&rdquo; and &ldquo;your&rdquo; will also
        refer to that organization, wherever possible.
      </p>
      <p>
        You must be over 13 years of age to use the Services, and children under
        the age of 13 cannot use or register for the Services. If you are over
        13 years of age but are not yet of legal age to form a binding contract
        (in many jurisdictions, this age is 18), then you must get your parent
        or guardian to read these Terms and agree to them for you before you use
        the Services. If you are a parent or guardian and you provide your
        consent to your child&rsquo;s registration with the Services, you agree
        to be bound by these Terms with respect of your child&rsquo;s use of the
        Services.
      </p>
      <p>
        You agree your purchases and/or use of the Services are not contingent
        on the delivery of any future functionality or features or dependent on
        any oral or written public comments made by Slab or any of its
        affiliates regarding future functionality or features.
      </p>
      <strong>
        <p>
          BY USING, DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING THE SERVICES
          OR ANY MATERIALS INCLUDED IN OR WITH THE SERVICES, YOU HEREBY AGREE TO
          BE BOUND BY THESE TERMS. IF YOU DO NOT ACCEPT THESE TERMS, THEN YOU
          MAY NOT USE, DOWNLOAD, INSTALL, OR OTHERWISE ACCESS THE SERVICES.
        </p>
      </strong>
      <p>
        Please read the Terms of Use carefully before you start to use the
        Services. By using the Services or by clicking to accept or agree to the
        Terms of Use when this option is made available to you, you accept and
        agree to be bound and abide by these Terms of Use and our {privacy},
        incorporated herein by reference. If you do not want to agree to these
        Terms of Use or the {privacy}, you must not access or use the Services.
      </p>
    </section>
    <section>
      <h2>2. Changes to these Terms of Use</h2>
      <p>
        We may revise and update these Terms of Use from time to time in our
        sole discretion. Any changes to these Terms of Use will also be posted
        here and available from links to these Terms of Use included on our
        Website. All changes are effective immediately when posted and apply to
        all access to and use of the Services thereafter.
      </p>
      <p>
        Your continued use of the Website, or other Services following the
        posting of revised Terms of Use means that you accept and agree to the
        changes. You are expected to check this page from time to time so you
        are aware of any changes, as they are binding on you. If you have an
        account, we may also notify you by email or otherwise as reasonably
        determined by us or required under applicable law. It is important that
        we have an up-to-date email address in your account so that we may
        notify you of such changes.
      </p>
      <p>
        Additionally, we may at any time, for any reason, and without notice or
        liability: (1) modify, suspend, or terminate operation of or access to
        the Website, or any portion thereof; (2) change, revise, or modify the
        Website, or the Services or any portion thereof; (3) interrupt the
        operation of the Website, or the Services or any portion thereof for
        maintenance and support; (4) impose limits on certain features and
        Services, or restrict access to the Website, or the Services; and/or (5)
        terminate the authorization, rights, and license given herein. Upon any
        termination, the rights and licenses granted to you herein shall
        terminate.
      </p>
    </section>
    <section>
      <h2>3. Your Account</h2>
      <p>
        In the course of registering for or using the Services, you may be
        required to provide Slab with certain information, including your name,
        organization name, email, and password (&ldquo;Credentials&rdquo;). Slab
        handles such information with the utmost attention, care and security.
        Nonetheless, you, not Slab, shall be responsible for maintaining and
        protecting your Credentials in connection with the Services. If your
        contact information or other information relating to your account
        changes, you must notify Slab promptly and keep such information
        current. You are solely responsible for any activity using your
        Credentials, whether or not you authorized that activity. You should
        immediately notify Slab of any unauthorized use of your Credentials or
        if your email or password has been hacked or stolen. If you discover
        that someone is using your Credentials without your consent, or you
        discover any other breach of security, you agree to notify Slab
        immediately.
      </p>
      <p>
        If an organization invited or otherwise provided you with your account,
        that organization may have rights to your account and may: manage your
        account, reset your password, or suspend or cancel your account; view
        your account&rsquo;s usage and profile data, including how and when your
        account is used; and read or store Content in your account. If you are
        an individual user of the Services, and the domain of the primary email
        address associated with your account is owned by an organization and was
        assigned to you as an employee, contractor or member of such
        organization, and that organization wishes to establishes a commercial
        relationship with us and add your account to such relationship, then, if
        you do not change the email address associated with your account, your
        account may become subject to the commercial relationship between Slab
        and such organization and controlled by such organization.
      </p>
      <p>
        We reserve the right to withdraw or amend the Website and any Service or
        material we provide in our sole discretion without notice. We will not
        be liable if for any reason all or any part of the Services is
        unavailable at any time or for any period. From time to time, we may
        restrict access to some parts of the Services to users, including
        registered users.
      </p>
      <p>You are responsible for:</p>
      <ul>
        <li>
          Making all arrangements necessary for you to have access to the
          Services; and
        </li>
        <li>
          Ensuring that all persons who access the Services through your
          internet connection and on your mobile device are aware of these Terms
          of Use and comply with them.
        </li>
      </ul>
      <p>
        To access the Services or some of the resources offered through the
        Services, you may be asked to provide certain registration details or
        other information. It is a condition of your use of the Services that
        all the information you provide on or to the Services is correct,
        current and complete. You agree that all information you provide to
        register with the Services or otherwise, including but not limited to
        through the use of any interactive features on or through the Services,
        is governed by our {privacy}, and you consent to all actions we take
        with respect to your information consistent with our {privacy}.
      </p>
      <p>
        If you choose, or are provided with, a user name (your email address),
        password or any other piece of information as part of our security
        procedures, you must treat such information as confidential, and you
        must not disclose it to any other person or entity. You also acknowledge
        that your account is personal to you and agree not to provide any other
        person with access to the Services or portions of it using your user
        name, password or other security information. You agree to notify us
        immediately of any unauthorized access to or use of your user name or
        password or any other breach of security. You also agree to ensure that
        you exit from your account at the end of each session. You should use
        particular caution when accessing your account from a public or shared
        computer so that others are not able to view or record your password or
        other personal information.
      </p>
      <p>
        We have the right to disable any user name, password or other
        identifier, whether chosen by you or provided by us, at any time in our
        sole discretion for any or no reason, including if, in our opinion, you
        have violated any provision of these Terms of Use.
      </p>
    </section>
    <section>
      <h2>4. Content</h2>
      <p>
        A variety of information, reviews, recommendations, messages, comments,
        posts, text, graphics, software, photographs, videos, data, and other
        materials (&ldquo;Content&rdquo;) may be made available through the
        Services by Slab or its suppliers (&ldquo;Slab-Supplied Content&rdquo;).
        While Slab strives to keep the Content that it provides through the
        Services accurate, complete, and up-to-date, Slab cannot guarantee, and
        is not responsible for the accuracy, completeness, or timeliness of any
        Slab-Supplied Content.
      </p>
      <p>
        You acknowledge that you will be able to create, transmit, publish or
        display information (such as data files, written text, computer
        software, music, audio files or other sounds, photographs, videos or
        other images) through use of the Services. All such information is
        referred to below as &ldquo;User Content.&rdquo;
      </p>
      <p>
        You agree that you are solely responsible for (and that Slab has no
        responsibility to you or to any third party for) any User Content, and
        for the consequences of your actions (including any loss or damage which
        Slab may suffer) in connection with such User Content. If you are
        registering for these Services on behalf of an organization, you also
        agree that you are also responsible for the actions of associated Users
        and for any User Content that such associated Users might upload,
        record, publish, post, link to, or otherwise transmit or distribute
        through use of the Services. Furthermore, you acknowledge that Slab does
        not control or actively monitor Content uploaded by users and, as such,
        does not guarantee the accuracy, integrity or quality of such Content.
        You acknowledge that by using the Services, you may be exposed to
        materials that are offensive, indecent or objectionable. Under no
        circumstances will Slab be liable in any way for any such Content. You
        will: (a) be solely responsible for the nature, quality and accuracy of
        your User Content; (b) ensure that your User Content (including the
        storage or transmission thereof) complies with these Terms and any and
        all applicable laws, and regulations; (c) promptly handle and resolve
        any notices and claims relating to your User Content, including any
        notices sent to you by any person claiming that any User Content
        violates any person&rsquo;s rights, such as take-down notices pursuant
        to the Digital Millennium Copyright Act and any other notices; and (d)
        maintain appropriate security, protection and backup copies of your User
        Content, which may include, your use of additional encryption technology
        to protect the Content from unauthorized access.
      </p>
      <p>
        Slab may refuse to store, provide, or otherwise maintain your User
        Content for any or no reason. Slab may remove your User Content from the
        Services at any time if you violate these Terms or if the Services are
        canceled or suspended. If User Content is stored using the Services with
        an expiration date, Slab may also delete the User Content as of that
        date. User Content that is deleted may be irretrievable. You agree that
        Slab will have no liability of any kind as a result of the deletion of,
        correction of, destruction of, damage to, loss of or failure to store or
        encrypt any User Content.
      </p>
      <p>
        Slab reserves the right (but shall have no obligation) to remove User
        Content from the Services, in its discretion. You agree to immediately
        take down any Content that violates these Terms, including pursuant to a
        takedown request from Slab. In the event that you elect not to comply
        with a request from Slab to take down certain Content, Slab reserves the
        right to directly take down such Content.
      </p>
      <p>
        By submitting, posting or otherwise uploading User Content on or through
        the Services you give Slab a worldwide, royalty-free, and non-exclusive
        license to reproduce, adapt, modify, translate, publish, publicly
        perform, publicly display and distribute such User Content for the
        purpose of enabling Slab to provide you with the Services, and for the
        limited purposes stated in our Privacy Policy.
      </p>
    </section>
    <section>
      <h2>5. Proprietary Rights</h2>
      <p>
        You acknowledge and agree that Slab (or Slab&rsquo;s licensors) own all
        legal right, title and interest in and to the Services and Slab-Supplied
        Content and that the Services and Slab-Supplied Content are protected by
        copyrights, trademarks, patents, or other proprietary rights and laws
        (whether those rights happen to be registered or not, and wherever in
        the world those rights may exist).
      </p>
      <p>
        Except as provided in Section 4, Slab acknowledges and agrees that it
        obtains no right, title or interest from you (or your licensors) under
        these Terms in or to any Content that you create, submit, post,
        transmit, share or display on, or through, the Services, including any
        intellectual property rights which subsist in that Content (whether
        those rights happen to be registered or not, and wherever in the world
        those rights may exist). Unless you have agreed otherwise in writing
        with Slab, you agree that you are responsible for protecting and
        enforcing those rights and that Slab has no obligation to do so on your
        behalf.
      </p>
      <p>
        You hereby agree that Slab may use your organization name and logo on
        its website and in its promotional materials to identify you as a Slab
        customer.
      </p>
    </section>
    <section>
      <h2>6. License from Slab and Prohibited Uses</h2>
      <p>
        Slab gives you a personal, worldwide, royalty-free, non-assignable and
        non-exclusive license to use the software provided to you as part of the
        Services. This license is solely to allow you to access the Services for
        your (or your organization&rsquo;s) non-commercial or internal business
        purposes, in the manner permitted by these Terms.
      </p>
      <Text center>
        <h3>Prohibited Uses:</h3>
      </Text>
      <p>You agree not to use the Services:</p>
      <ul>
        <li>
          In any way that violates any applicable federal, state, local or
          international law or regulation (including, without limitation, any
          laws regarding the export of data or software to and from the US or
          other countries).
        </li>
        <li>
          For the purpose of exploiting, harming or attempting to exploit or
          harm minors in any way by exposing them to inappropriate content,
          asking for personally identifiable information or otherwise.
        </li>
        <li>
          To transmit, or procure the sending of, any advertising or promotional
          material, including any &ldquo;junk mail&rdquo;, &ldquo;chain
          letter&rdquo; or &ldquo;spam&rdquo; or any other similar solicitation.
        </li>
        <li>
          To impersonate or attempt to impersonate Slab, a Slab employee,
          another user or any other person or entity (including, without
          limitation, by using e-mail addresses associated with any of the
          foregoing).
        </li>
        <li>
          To engage in any other conduct that restricts or inhibits
          anyone&rsquo;s use or enjoyment of the Services or which, as
          determined by us, may harm Slab or users of the Services or expose
          them to liability.
        </li>
      </ul>
      <p>Additionally, you agree not to:</p>
      <ul>
        <li>
          Use the Services in any manner that could disable, overburden, damage,
          or impair the site or interfere with any other party&rsquo;s use of
          the Services, including their ability to engage in real time
          activities through the Website or any mobile application used to
          access the Services.
        </li>
        <li>
          Use any robot, spider or other automatic device, process or means to
          access the Website or any mobile application used to access the
          Services for any purpose, including monitoring or copying any of the
          material on the Website or any mobile application used to access the
          Services.
        </li>
        <li>
          Use any manual process to monitor or copy any of the material on the
          Website or any mobile application used to access the Services or for
          any other unauthorized purpose without our prior written consent.
        </li>
        <li>
          Use any device, software or routine that interferes with the proper
          working of the Services.
        </li>
        <li>
          Introduce any viruses, trojan horses, worms, logic bombs or other
          material which is malicious or technologically harmful.
        </li>
        <li>
          Attempt to gain unauthorized access to, interfere with, damage or
          disrupt any parts of the Website or any mobile application used to
          access the Services, the server on which the Website is hosted or
          otherwise used by any mobile application used to access the Services ,
          or any server, computer or database connected to the Website or any
          mobile application used to access the Services.
        </li>
        <li>
          Attack the Website or any mobile application used to access the
          Services via a denial-of-service attack or a distributed
          denial-of-service attack.
        </li>
        <li>
          Otherwise attempt to interfere with the proper working of the
          Services.
        </li>
      </ul>
    </section>
    <section>
      <h2>7. Pricing and Other Terms and Conditions</h2>
      <p>
        Subject to the Terms, the Services are provided to you without charge up
        to certain usage limits, and usage in excess of these limits may require
        purchase of additional resources and the payment of fees. Please see
        Slab&rsquo;s {pricing} for details regarding pricing for the Services.
      </p>
      <p>
        You agree that your order through the Website or any mobile application
        used to access the Services is an offer to buy, under these Terms of
        Use, all items listed in your order. All orders must be accepted by us
        or we will not be obligated to sell the items to you. We may choose not
        to accept orders at our sole discretion, even after we send you a
        confirmation email with your order number and details of the items you
        have ordered.
      </p>
      <p>
        All prices, discounts, and promotions posted on the Website or any
        mobile application used to access the Services are subject to change
        without notice. The price charged for an online item will be the price
        in effect at the time the order is fulfilled and will be set out in your
        order confirmation email. Price increases will only apply to orders
        placed after such changes. We strive to display accurate price
        information; however, we may, on occasion, make inadvertent
        typographical errors, inaccuracies or omissions related to pricing. We
        reserve the right to correct any errors, inaccuracies, or omissions at
        any time and to cancel any orders arising from such occurrences.
      </p>
      <p>
        Payment must be received by us before our acceptance of any order. We
        accept all major credit cards for purchases. You represent and warrant
        that (a) the credit card information you supply to us is true, correct
        and complete, (b) you are duly authorized to use such credit card for
        the purchase, (c) charges incurred by you will be honored by your credit
        card company, and (d) you will pay charges incurred by you at the posted
        prices, including delivery charges and all applicable taxes, if any,
        regardless of the amount quoted on the Website or any mobile application
        used to access the Services at the time of your order.
      </p>
    </section>
    <section>
      <h2>8. Modification and Termination of Services</h2>
      <p>
        Slab is constantly innovating in order to provide the best possible
        experience for its users. You acknowledge and agree that the form and
        nature of the Services which Slab provides may change from time to time
        without prior notice to you, subject to the terms in its Privacy Policy.
        Changes to the form and nature of the Services will be effective with
        respect to all versions of the Services; examples of changes to the form
        and nature of the Services include without limitation changes to fee and
        payment policies, security patches, added functionality, automatic
        updates, and other enhancements. Any new features that may be added to
        the website or the Services from time to time will be subject to these
        Terms, unless stated otherwise.
      </p>
      <p>
        You may terminate these Terms at any time by canceling your account on
        the Services. You will not receive any refunds if you cancel your
        account.
      </p>
      <p>
        You agree that Slab, in its sole discretion and for any or no reason,
        may terminate your account or any part thereof. You agree that any
        termination of your access to the Services may be without prior notice,
        and you agree that Slab will not be liable to you or any third party for
        such termination.
      </p>
      <p>
        You are solely responsible for exporting your Content from the Services
        prior to termination of your account for any reason, provided that if we
        terminate your account, we will endeavor to provide you a reasonable
        opportunity to retrieve your Content.
      </p>
      <p>
        Upon any termination of the Services or your account these Terms will
        also terminate, but all provisions of these Terms which, by their
        nature, should survive termination, shall survive termination,
        including, without limitation, ownership provisions, warranty
        disclaimers, and limitations of liability.
      </p>
    </section>
    <section>
      <h2>9. Disclaimer of Warranties</h2>
      <p>
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES ARE AT
        YOUR SOLE RISK AND THAT THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo;
        AND &ldquo;AS AVAILABLE.&rdquo;
      </p>
      <p>
        YOU UNDERSTAND THAT WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES
        AVAILABLE FOR DOWNLOADING FROM THE INTERNET, INCLUDING ON THE WEBSITE OR
        THROUGH ANY MOBILE APPLICATION USED TO ACCESS THE SERVICES, WILL BE FREE
        OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE RESPONSIBLE FOR DEPLOYING
        ANTI-VIRUS AND OTHER SIMILAR PROTECTIONS ON YOUR COMPUTER OR MOBILE
        DEVICE. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
        DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY
        HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER
        PROGRAMS, MOBILE DEVICES, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR
        USE OF THE WEBSITE, ANY MOBILE APPLICATION USED TO ACCESS THE SERVICES,
        OR ANY SERVICES OBTAINED THROUGH THE WEBSITE OR SUCH MOBILE APPLICATION,
        OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON ANY OF THE FOREGOING,
        OR ON ANY WEBSITE LINKED TO IT.
      </p>
      <p>
        SLAB, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS MAKE NO EXPRESS
        WARRANTIES AND DISCLAIM ALL IMPLIED WARRANTIES REGARDING THE SERVICES,
        INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE AND NON-INFRINGEMENT. WITHOUT LIMITING THE GENERALITY
        OF THE FOREGOING, SLAB, ITS SUBSIDIARIES AND AFFILIATES, AND ITS
        LICENSORS DO NOT REPRESENT OR WARRANT TO YOU THAT: (A) YOUR USE OF THE
        SERVICES WILL MEET YOUR REQUIREMENTS, (B) YOUR USE OF THE SERVICES WILL
        BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR, AND (C) USAGE DATA
        PROVIDED THROUGH THE SERVICES WILL BE ACCURATE.
      </p>
      <p>
        NOTHING IN THESE TERMS, INCLUDING SECTIONS 10 AND 11, SHALL EXCLUDE OR
        LIMIT SLAB&rsquo;S WARRANTY OR LIABILITY FOR LOSSES WHICH MAY NOT BE
        LAWFULLY EXCLUDED OR LIMITED BY APPLICABLE LAW.
      </p>
    </section>
    <section>
      <h2>10. Limitation of Liability</h2>
      <p>
        SUBJECT TO SECTION 9 ABOVE, YOU EXPRESSLY UNDERSTAND AND AGREE THAT
        SLAB, ITS SUBSIDIARIES AND AFFILIATES, AND ITS LICENSORS SHALL NOT BE
        LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR
        EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND UNDER
        ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED TO, ANY
        LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY LOSS OF
        GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED, COST OF
        PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER INTANGIBLE LOSS.
        THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL
        PURPOSE OF ANY LIMITED REMEDY.
      </p>
      <p>
        THE LIMITATIONS ON SLAB&rsquo;S LIABILITY TO YOU IN THIS SECTION SHALL
        APPLY WHETHER OR NOT SLAB HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE
        OF THE POSSIBILITY OF ANY SUCH LOSSES ARISING.
      </p>
      <p>
        SOME STATES AND JURISDICTIONS MAY NOT ALLOW THE LIMITATION OR EXCLUSION
        OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
        LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT SHALL
        SLAB&rsquo;S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES
        OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR
        OTHERWISE) EXCEED THE AMOUNT THAT YOU HAVE ACTUALLY PAID FOR THE
        SERVICES IN THE PAST TWELVE MONTHS, OR ONE HUNDRED DOLLARS ($100.00),
        WHICHEVER IS GREATER.
      </p>
      <p>
        THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
        LIMITED UNDER APPLICABLE LAW.
      </p>
    </section>
    <section>
      <h2>11. Indemnification</h2>
      <p>
        You agree to hold harmless and indemnify Slab, and its subsidiaries,
        affiliates, officers, agents, employees, advertisers, licensors,
        suppliers or partners (collectively &ldquo;Slab and Partners&rdquo;)
        from and against any third party claim arising from or in any way
        related to (a) your breach of the Terms, (b) your use of the Services,
        (c) your violation of applicable laws, rules or regulations in
        connection with the Services, or (d) your User Content, including any
        liability or expense arising from all claims, losses, damages (actual
        and consequential), suits, judgments, litigation costs and
        attorneys&rsquo; fees, of every kind and nature.
      </p>
    </section>
    <section>
      <h2>12. Governing Law and Jurisdiction</h2>
      <p>
        All matters relating to the Services and these Terms of Use and any
        dispute or claim arising therefrom or related thereto (in each case,
        including non-contractual disputes or claims), shall be governed by and
        construed in accordance with the internal laws of the State of
        California without giving effect to any choice or conflict of law
        provision or rule (whether of the State of California or any other
        jurisdiction).
      </p>
      <p>
        Any legal suit, action or proceeding arising out of, or related to,
        these Terms of Use or the Services brought by you hall be instituted
        exclusively in the federal courts of the United States or the courts of
        the State of California, in each case located in the City and County of
        San Francisco. We retain the right to bring any suit, action or
        proceeding against you for breach of these Terms of Use in your country
        of residence or any other relevant country. You waive any and all
        objections to the exercise of jurisdiction over you by such courts and
        to venue in such courts.
      </p>
    </section>
    <section>
      <h2>13. Copyright Policy</h2>
      <p>
        We respect the intellectual property rights of others and expect our
        users to do the same. In accordance with the Digital Millennium
        Copyright Act, Title 17, United States Code, Section 512(c)(2) (the
        &ldquo;DMCA&rdquo;), we will respond expeditiously to claims of
        copyright infringement committed using the Services if such claims are
        reported to our Designated Copyright Agent identified in the sample
        notice below.
      </p>
      <p>DMCA Notice of Alleged Infringement (&ldquo;Notice&rdquo;)</p>
      <ul>
        <li>
          Identify the copyrighted work that you claim has been infringed, or if
          multiple copyrighted works are covered by this Notice, you may provide
          a representative list of the copyrighted works that you claim have
          been infringed.
        </li>
        <li>
          Identify the material or link you claim is infringing (or the subject
          of infringing activity) and to which access is to be disabled,
          including at a minimum, if applicable, the URL of the link or the
          exact location where such material may be found.
        </li>
        <li>
          Provide your company affiliation (if applicable), mailing address,
          telephone number, and, if available, email address.
        </li>
        <li>
          Include both of the following statements in the body of the Notice:
          <ul>
            <li>
              &ldquo;I hereby state that I have a good faith belief that the
              disputed use of the copyrighted material is not authorized by the
              copyright owner, its agent, or the law (e.g., as a fair
              use).&rdquo;
            </li>
            <li>
              &ldquo;I hereby state that the information in this Notice is
              accurate and, under penalty of perjury, that I am the owner, or
              authorized to act on behalf of, the owner, of the copyright or of
              an exclusive right under the copyright that is allegedly
              infringed.&rdquo;
            </li>
          </ul>
        </li>
        <li>
          Provide your full legal name and your electronic or physical
          signature. Deliver this Notice, with all items completed, to our
          Designated Copyright Agent:
          <p className="address">
            Copyright Agent,
            <br />
            {name}
            <br />
            {mail}
            <br />
            {support}
          </p>
        </li>
      </ul>
    </section>
    <section>
      <h2>14. Feedback</h2>
      <p>
        You may choose to or we may invite you to submit comments or ideas about
        the Services, including without limitation about how to improve the
        Services or our products. By submitting any feedback, you agree that
        your disclosure is gratuitous, unsolicited and without restriction and
        will not place Slab under any fiduciary or other obligation, and that we
        are free to use such feedback without any additional compensation to
        you, and/or to disclose such feedback on a non-confidential basis or
        otherwise to anyone.
      </p>
    </section>
    <section>
      <h2>15. Miscellaneous</h2>
      <p>
        These Terms, together with our Privacy Policy and Pricing Terms
        constitutes the entire agreement between the parties relating to the
        Services and all related activities. These Terms shall not be modified
        except by a new posting of these Terms issued by us. If any part of
        these Terms is held to be unlawful, void, or unenforceable, that part
        shall be deemed severed and shall not affect the validity and
        enforceability of the remaining provisions. The failure of Slab to
        exercise or enforce any right or provision under these Terms shall not
        constitute a waiver of such right or provision. Any waiver of any right
        or provision by Slab must be in writing and shall only apply to the
        specific instance identified in such writing. You may not assign these
        Terms, or any rights or licenses granted hereunder, whether voluntarily,
        by operation of law, or otherwise without our prior written consent.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms or if you wish to make any
        complaint or claim with respect to the Services, please contact us at:{' '}
        {support}
      </p>
      <p>
        When submitting a complaint, please provide a brief description of
        nature of your complaint and the specific services to which your
        complaint relates.
      </p>
    </section>
  </LegalLayout>
);

export default Terms;
